import { ChevronRightIcon } from "@radix-ui/react-icons";

import { Button } from "~/components/ui/button";

export const CustomExpandButtonProductSummary = ({
  isExpanded,
}: {
  isExpanded: boolean;
}) => (
  <Button
    variant="linkInline"
    className="mt-2 gap-1 text-xs font-bold underline underline-offset-1"
  >
    {isExpanded ? "View Less" : "View All"}
    <ChevronRightIcon />
  </Button>
);

export const CustomExpandButtonProductInfo = ({
  isExpanded,
}: {
  isExpanded: boolean;
}) => (
  <div className="mt-8 flex w-full flex-row items-center">
    <div className="h-px w-full bg-neutral-7" />
    <Button
      className="h-[32px] shrink-0 self-center rounded-full border border-neutral-7 bg-background px-6 text-xs font-semibold text-brand-primary-black no-underline hover:no-underline"
      variant="linkInline"
    >
      {isExpanded ? "Show Less" : " Show More"}
    </Button>
    <div className="h-px w-full bg-neutral-7" />
  </div>
);

export const CustomExpandButtonGiftCardMessage = ({
  isExpanded,
}: {
  isExpanded: boolean;
}) => (
  <button
    type="button"
    className="text-nowrap inline-block w-fit whitespace-nowrap underline"
  >
    {isExpanded ? "see less" : "see more"}
  </button>
);

import type { AlgoliaSearchResultHit } from "~/algolia/algolia.types";
import type { Product } from "~/commerce-sap/.server/api/generated/__generated_apis";

export const isGiftCard = (
  product: Product | AlgoliaSearchResultHit,
): boolean => {
  return !!(
    product?.categories &&
    product.categories.some(category => category.code === "gift-card")
  );
};

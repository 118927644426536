import { cn } from "~/lib/ui";
import { CustomExpandButtonGiftCardMessage } from "~/routes/($locale)+/_product-details+/components/custom-buttons";

import { ExpandableText } from "../ui/expandable-text";
import { P } from "../ui/text";

type GiftCardSummaryProps = {
  recipientName?: string;
  recipientEmail?: string;
  giftCardMessage?: string;
  className?: string;
};

const GiftCardSummary = ({
  recipientName,
  recipientEmail,
  giftCardMessage,
  className,
}: GiftCardSummaryProps) => {
  return (
    <div className={cn("flex flex-col gap-2", className)}>
      <div className="flex flex-col gap-1">
        <P className="text-xs text-neutral-2">Recipient Name</P>
        <P className="font-semibold">{recipientName}</P>
      </div>
      <div className="flex flex-col gap-1">
        <P className="text-xs text-neutral-2">Recipient Email</P>
        <P className="font-semibold">{recipientEmail}</P>
      </div>
      <div className="flex flex-col gap-1">
        <P className="text-xs text-neutral-2">Message</P>
        <div className="flex flex-row text-sm font-semibold">
          <ExpandableText
            lines="2"
            button={CustomExpandButtonGiftCardMessage}
            className="max-w-[330px] text-ellipsis md:line-clamp-1 lg:overflow-hidden"
            flexDirection="flex-row"
          >
            <P>{giftCardMessage}</P>
          </ExpandableText>
        </div>
      </div>
    </div>
  );
};

export default GiftCardSummary;
